body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Montserrat', sans-serif !important;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
  opacity: 15%;
  border-radius: 5px 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #FFB8A1;
  border-radius: 5px 5px;
}


/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

::placeholder {
  color: #82868C;
  font-size: 12px;
}

.xeleron-logo{
  height: 60px;
}