.tableBox {
  background: #ffffff;
}

.tableBox .MuiPaper-root {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: none !important;
  transition: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  width: 100%;
  overflow: hidden;
}

.tableBox .MuiInputBase-root {
  width: max-content !important;
}

.tableHeading {
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #14009b !important;
  padding: 15px !important;
  /* padding-left: 30px !important; */
}

.tableBox .MuiTableContainer-root {
  border-radius: 8px !important;
}

.tableBox .MuiTableCell-root {
  padding: 15px;
  color: #000000;
  font-weight: 500;
  font-size: 14px !important;
}

.tableBox.small .MuiTableCell-root {
  padding: 5px 15px !important;
}

.tableBox.small .tableHeading {
  padding: 15px !important;
}

.myGoal .tableBox .MuiTableCell-root {
  padding: 30px 15px;
}

.tableBox .MuiTableContainer-root {
  max-height: 500px !important;
}

.tableBox .pagination {
  padding-top: 24px;
  padding-bottom: 24px;
}

.tableBox .pagination ul {
  justify-content: center;
}

.tableBox .reportButton {
  color: white;
  font-size: 12px !important;
  margin: 5px;
}

.tableBox.small .reportButton.primary-button {
  margin: 0 5px;
  padding: 4px 8px !important;
}

.tableBox .reportButton.type0 {
  background: #14009B !important;
  width: 55%;
}

.tableBox .reportButton.type1 {
  background: #1F384C !important;
  width: 55%;
}

.tableBox .reportButton.type2 {
  background: #14009B !important;
  width: 30%;
}

.tableBox .reportButton.type3 {
  background: #1F384C !important;
  width: 30%;
}

.tableBox .MuiTableRow-hover:hover {
  background-color: rgba(20, 0, 155, 0.06) !important;
}

.toogleSwitch .MuiSwitch-switchBase.Mui-checked {
  color: #14009B !important;
}

.toogleSwitch .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background: rgba(20, 0, 155, 0.17) !important;
}

.toogleSwitch .MuiSwitch-switchBase {
  color: #82868C !important;
}

.toogleSwitch .MuiSwitch-track {
  background: #C5CFD7 !important;
}

.MuiTableRow-hover {
  cursor: pointer;
}

.smallTag {
  font-weight: 600;
  font-size: 11px;
  color: #f56102;
  background: rgba(245, 97, 2, 0.07);
  border-radius: 100px;
  padding: 3px 10px;
}

.green {
  color: green;
}

.red {
  color: #d32f2f;
}

.tableBox .MuiTableRow-root {
  height: 80px;
}

.tableBox.small .MuiTableRow-root {
  height: 40px;
}

@media screen and (max-width: 1000px) {
  .tableBox .MuiTableCell-root {
    padding: 10px;
    font-size: 12px !important;
  }

  .tableHeading {
    padding: 10px !important;
  }

  .tableBox .MuiTableContainer-root {
    max-height: fit-content !important;
  }
}