.loader_block {
  position: fixed;
  display: block;
  left: 0%;
  top: 0%;
  width: 100%;
  height: 100% !important;
  z-index: 9999;
  background-color: rgba(255, 255, 255, 0.7);
}

.root {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.loader_block span {
  left: 0%;
  top: 0%;
  position: relative;
}

.loader_block .makeStyles-root-1 {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
}

.loader_block span img {
  max-width: 100%;
  height: auto;
  opacity: 0.5;
}

.hide-display {
  display: none;
}

.loader_block .loader {
  position: relative;
}

.loader_block .loader-img {
  position: fixed;
  left: 50%;
  top: 50%;
  margin: 0 auto;
  animation-name: load;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
}

.loader_block .MuiCircularProgress-indeterminate {
  width: 25px !important;
  height: 25px !important;
}

.loader_block .MuiCircularProgress-svg {
  color: #14009B;
}

@keyframes load {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

@media only screen and (max-width: 500px) {
  .loader_block span {
    top: 25% !important;
  }

  .loader_block .loader-img {
    left: 40%;
  }
}