* {
  font-family: 'Montserrat', sans-serif !important;
}

.align-right {
  text-align: right !important;
}

.align-left {
  text-align: left !important;
}

.align-center {
  text-align: center !important;
}

.flex {
  display: flex !important;
}

.flex-space {
  display: flex !important;
  justify-content: space-between !important;
}

.flex-center {
  display: flex !important;
  justify-content: center !important;
}

.flex-wrap {
  display: flex !important;
  flex-wrap: wrap !important;
}

.flex-space-wrap {
  display: flex !important;
  justify-content: space-between !important;
  flex-wrap: wrap !important;
}

.flex-end {
  display: flex !important;
  justify-content: flex-end !important;
}

.bg-color-grey {
  background-color: #E5E5E5;
}

.bg-color-blue {
  background-color: #14009b !important;
}

.bg-color-login {
  background-color: #F5F6F8 !important;
}

.bg-color-login-other {
  background-color: #F9F9F9 !important;
}

.bg-color-orange {
  background: rgba(245, 97, 2, 0.5)
}

.bg-color-purple {
  background: rgba(20, 0, 155, 0.5);
}

.bg-color-peach {
  background: #FFB8A1;
}

.font-color-grey {
  color: #4C5862;
}

.size-9 {
  font-size: 9px !important;
}

.size-10 {
  font-size: 10px !important;
}

.size-11 {
  font-size: 11px !important;
}

.size-12 {
  font-size: 12px !important;
}

.size-13 {
  font-size: 13px !important;
}

.size-14 {
  font-size: 14px !important;
}

.size-15 {
  font-size: 15px !important;
}

.size-16 {
  font-size: 16px !important;
}

.size-18 {
  font-size: 18px !important;
}

.size-20 {
  font-size: 20px !important;
}

.size-22 {
  font-size: 22px !important;
}

.size-24 {
  font-size: 24px !important;
}

.font-bold {
  font-weight: 500 !important;
}

.redStar {
  color: red !important;
}

.orange-dots:hover {
  background-color: rgb(214, 207, 207);
  border-radius: 100%;
  padding: 1px;
}

.css-3bmhjh-MuiPaper-root-MuiPopover-paper, .css-1dmzujt{
  max-width: 600px !important;
}

.css-1x51dt5-MuiInputBase-input-MuiInput-input, .css-mnn31{
  color: #000 !important;
  font-weight: 500 !important;
  font-size: 14px !important;
}