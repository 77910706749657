.modalBasic .MuiDialog-paper{
    background: url("../../assets/orangeBg.svg") left top no-repeat,
    url("../../assets/bgBlueSmallRt.svg") right bottom no-repeat !important;
    background-color: white !important;
    text-align: center !important;
    padding: 60px 0 30px 0 !important;
   width: 600px !important;
}
.widthModal .MuiDialog-paper{
    background: url("../../assets/bgCircleLt.svg") left top no-repeat,
    url("../../assets/bgCircleBlueRt.svg") right bottom no-repeat !important;
    background-color: white !important;
    text-align: center !important;
    padding:30px 0 60px 0 !important; 
    max-width: 800px !important;
    width: 800px !important;
}
@media screen and (max-width: 600px) {
    .modalBasic .MuiDialog-paper{
        padding: 20px 0 20px 0 !important; 
    }
    .modalBasic .MuiTypography-root{
        font-size: 15px !important;
    }
    .modalBasic .MuiDialogContent-root{
        padding: 20px !important;
        padding-top: 0 !important;
    }
    .widthModal .MuiDialog-paper{
        padding: 20px 0 20px 0 !important; 
    }
    .widthModal .MuiTypography-root{
        font-size: 15px !important;
    }
    .widthModal .MuiDialogContent-root{
        padding: 20px !important;
        padding-top: 0 !important;
    }
}