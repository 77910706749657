.tableBoxOther {
  background: #F8F8F8 !important;
  border-radius: 8px;
  max-height: 700px;
  overflow: auto;
}

.tableBoxOther .MuiPaper-root {
  background-color: transparent !important;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: none !important;
  transition: none !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  width: 100%;
  overflow: hidden;
}

.tableBoxOther .MuiInputBase-root {
  width: max-content !important;
}

.tableHeading {
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #14009b !important;
  padding: 15px !important;
  /* padding-left: 30px !important; */
}

.tableBoxOther .MuiTableContainer-root {
  border-radius: 8px !important;
}

.tableBoxOther .MuiTableCell-root {
  padding: 15px;
  color: #000000;
  font-weight: 500;
  font-size: 14px !important;
  background: #F8F8F8 !important;
}

.myGoal .tableBoxOther .MuiTableCell-root {
  padding: 30px 15px;
}

.tableBoxOther .MuiTableContainer-root {
  max-height: 500px !important;
}

.tableBoxOther .pagination {
  padding-top: 24px;
  padding-bottom: 24px;
}

.tableBoxOther .pagination ul {
  justify-content: center;
}

.tableBoxOther .reportButton {
  color: white;
  font-size: 12px !important;
  margin: 5px;
}

.tableBoxOther .reportButton.type0 {
  background: #14009B !important;
  width: 55%;
}

.tableBoxOther .reportButton.type1 {
  background: #1F384C !important;
  width: 55%;
}

.tableBoxOther .reportButton.type2 {
  background: #14009B !important;
  width: 30%;
}

.tableBoxOther .reportButton.type3 {
  background: #1F384C !important;
  width: 50%;
}

.tableBoxOther .MuiTableRow-hover:hover {
  background-color: rgba(20, 0, 155, 0.06) !important;
}

.toogleSwitch .MuiSwitch-switchBase.Mui-checked {
  color: #14009B !important;
}

.toogleSwitch .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background: rgba(20, 0, 155, 0.17) !important;
}

.toogleSwitch .MuiSwitch-switchBase {
  color: #82868C !important;
}

.toogleSwitch .MuiSwitch-track {
  background: #C5CFD7 !important;
}

.MuiTableRow-hover {
  cursor: pointer;
}

.smallTag {
  font-weight: 600;
  font-size: 11px;
  color: #f56102;
  background: rgba(245, 97, 2, 0.07);
  border-radius: 100px;
  padding: 3px 10px;
}

.green {
  color: green;
}

.red {
  color: #d32f2f;
}

.tableBoxOther .MuiTableRow-root {
  height: 80px;
}

.cardBoxCircle {
  background: #ff9777;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  text-align: center;
  margin-right: 8px;
}

.cardBoxCircleText {
  position: relative;
  top: 25%;
  text-transform: uppercase;
  color: #fff;
}

.extend-btn {
  background: none;
  border: none;
  font-weight: 600 !important;
  display: flex;
  margin: 0 0 0 6px !important;
  padding-top: 12px;
  font-size: 14px;
  color: #000 !important;
  text-transform: none !important;
  cursor: pointer;
}

.extend-btn:hover {
  background: none !important;
}

.extend-btn span {
  margin-top: 4px !important;
  margin-left: 5px !important;
}

.extend-btn:disabled {
  color: #82868C !important;
  cursor: not-allowed !important;
}

.iconBtn-with-text {
  display: block !important;
  margin-top: -5px;
}

.btn-small-text {
  font-size: 10px;
  margin-top: -5px !important;
}

.tableBoxOther .td-cell-custom {
  color: rgb(149, 148, 148) !important;
}

@media screen and (max-width: 1000px) {
  .tableBoxOther .MuiTableCell-root {
    padding: 10px;
    font-size: 12px !important;
  }

  .tableHeading {
    padding: 10px !important;
  }

  .tableBoxOther .MuiTableContainer-root {
    max-height: fit-content !important;
  }
}