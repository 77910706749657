.small-table-tile {
    background: #fff;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.10));
    border: 1px solid #d9e6ff;
    border-radius: 5px;
    margin: 10px;
}

.pm-label-small {
    color: #14009b;
    font-size: 14px !important;
    line-height: 15px !important;
    font-weight: 1000 !important;
    background: none !important;
    padding: 7px 7px 5px !important;
    text-align: center;
}

.viewGoalsBtn {
    color: #14009b !important;
    font-size: 12px;
    font-weight: bolder;
}

@media screen and (max-width:415px) {
    .checkboxDiv {
        width: 110px !important;
    }
}

@media screen and (max-width:420px) {
    .peer-name {
        line-height: 15px;
        text-align: center;
    }
}