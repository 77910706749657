.primary-button{
    font-weight: 600 !important;
    font-size: 13px !important;
    text-transform: capitalize !important;
    padding: 8px !important;
    border-radius: 6px !important;
}
@media screen and (max-width: 600px) {
    .primary-button{
        font-size: 11px !important;
    } 
}