.selectDropdown .MuiFormControl-root {
  width: 100% !important;
}

.selectDropdown .MuiSelect-select {
  padding: 9px 0px 9px 20px !important;
  padding-right: 35px !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #1f384c !important;
  background-color: #fff;
}

.selectDropdown .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

/* .selectDropdown .MuiOutlinedInput-root.Mui-error{
  border: 1px solid #d32f2f !important;
} */

.MuiMenu-list {
  background: url("../../assets/bgBlueSmallRt.svg") right bottom no-repeat !important;
  padding: 0 !important;
  width: 100% !important;
}

.selectDropdownPlaceholder {
  font-weight: 500;
  font-size: 12px;
  color: #82868c;
}

.selectDropdown .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
  border: 1px solid #ccc !important;
  border-radius: 5px !important;
}

.selectDropdown .MuiFormControl-root label.MuiInputLabel-root {
  font-size: 15px !important;
  top: -5px;
  line-height: 1;
  padding: 0 2px;
  left: -13px;
}

.selectDropdown .MuiFormControl-root label.MuiInputLabel-root.Mui-focused {
  color: rgba(0, 0, 0, 0.6);
}