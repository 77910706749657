.mySurvey .dropDownField {
  width: 150px;
  float: left;
  margin: -50px 40px 0 0;
}

.mySurvey .dropDownField .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
  background: #F5F6F8 !important;
}

.mySurvey .tableBox {
  margin: 50px 0 0;
}

.mySurvey .noData {
  margin: 50px 0 0;
  padding: 50px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #14009b;
}