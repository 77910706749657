.pageNotFound {
  background: url("../../../assets/bgCircleLt.svg") left top no-repeat,
    url("../../../assets/pattern.svg") right top no-repeat,
    url("../../../assets/bgCircleBlueRt.svg") right bottom no-repeat;
  box-shadow: 0px 7px 20px rgba(40, 41, 61, 0.08);
  border-radius: 8px;
  background-color: #ffffff;
  width: 100%;
  height: 100vh;
}

.pageNotFoundInner {
  text-align: center;
  padding-top: 60px;
}

.notFoundImg1 {
  text-align: center;
  margin-bottom: 40px;
}

.noAuthImg {
  text-align: center;
  margin-bottom: 24px;
}

.image404 {
  position: relative;
  width: 30%;
}

.oopsText {
  font-weight: 700;
  font-size: 54px;
  color: #d33367;
}

.pageNotFoundText {
  font-weight: 700;
  font-size: 34px;
  color: #1f384c;
  margin-top: 15px;
}

.pageNotFoundButton {
  padding-top: 35px;
  padding-bottom: 25px;
  text-align: center;
}

.notFoundInnerButton {
  width: 451px;
  margin: auto;
}

.goToHomeButton {
  color: white !important;
  background-color: #f56102 !important;
  border-color: #f56102 !important;
  width: 100%;
}

@media screen and (max-width: 1000px) {
  .notFoundInnerButton {
    width: 301px;
  }

  .image404 {
    width: 40%;
  }

  .oopsText {
    font-size: 30px;
  }

  .pageNotFoundText {
    font-size: 20px;
    margin-top: 10px;
  }

  .notFoundImg1 {
    text-align: center;
    margin-bottom: 30px;
  }

  .noAuthImg {
    text-align: center;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 600px) {
  .pageNotFoundInner {
    text-align: center;
    padding-top: 120px;
  }

  .image404 {
    width: 80%;
  }

  .notFoundInnerButton {
    width: 201px;
  }
}