.breadCrumbs {
  display: flex;
  justify-content: center;
  padding: 30px 0 20px;
  /* position: fixed; */
  width: -webkit-fill-available;
  background-color: white;
  z-index: 999;
}

.breadCrumbsStepsActive {
  width: 25%;
  font-weight: 600;
  font-size: 13px;
  color: #14009b;
  cursor: pointer;
  text-align: center;
}

.breadCrumbsSteps {
  font-weight: 600;
  color: #c4c4c4;
  font-size: 13px;
  width: 25%;
  text-align: center;
  color: #14009b;
  cursor: pointer;
}

.activeLine {
  width: 95%;
  margin-left: 0;
  border-radius: 100px;
  height: 3px;
  border: 1px solid #f56102;
  background: #f56102;
}

.inActiveLine {
  width: 95%;
  margin-left: 0;
  border-radius: 100px;
  height: 3px;
  border: 1px solid #c4c4c4;
  background: #c4c4c4;
}

@media screen and (max-width: 1000px) {
  .next {
    width: 40%;
  }

  .mapCardBox {
    padding: 30px 10px 0 10px;
  }

  .breadCrumbs {
    padding-left: 10px;
    padding-right: 10px;
  }

  .breadCrumbsStepsActive {
    font-size: 10px;
  }

  .breadCrumbsSteps {
    font-size: 10px;
  }
}

@media screen and (max-width: 600px) {
  .breadCrumbs {
    padding: 20px 0 0;
  }

  .inActiveLine {
    height: 1px;
  }

  .activeLine {
    height: 1px;
  }

  .breadCrumbsStepsActive {
    width: 30%;
  }

  .breadCrumbsSteps {
    width: 30%;
  }
}