.ikigai-header{
   margin-top: 68px; 
   height: 77px; 
   background: #F56102;
   width: auto;
   display: flex;
   align-items: center;
}

.ikigai-header-text{
   color: #FFFFFF;
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 600;
   font-size: 13px;
   line-height: 16px;
   vertical-align: center;
   margin-left: 5%;
}

.heading-ikigai{
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 600;
   font-size: 24px;
   line-height: 29px;
   /* margin-bottom: 0; */
   color: #000000;
   margin-left: 5%;
}

.description-ikigai-heading{
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 500;
   font-size: 13px;
   line-height: 0px;
   color: #9C9C9C;
   margin-left: 5%;
}