.dashBoardPage {
  width: 100%;
  height: 100%;
  background: url("../../../assets/bgCircleLt.svg") left top no-repeat,
    url("../../../assets/shadowLt.svg") right top no-repeat,
    url("../../../assets/shadowRt.svg") left bottom no-repeat,
    url("../../../assets/bgCircleBlueRt.svg") right bottom no-repeat;
  margin-top: 70px;
  min-height: 94vh;

}

.dashBoardContainer {
  padding: 1px 50px 50px 50px;
}

.topRow {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
}

.topRowWrap {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-radius: 10px;
}

.colCardWrap {
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 15px;
  width: 30%;
  margin: 20px 10px;
}

.colCardWrapHover {
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 15px;
  width: 30%;
  margin: 20px 10px;
}

.colCardWrapHover:hover {
  transform: scale(1.01);
}

.colCard1 {
  background: #ffffff;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 30px;
  max-width: 385px;
  width: 25%;
  margin-right: 30px;
  margin-bottom: 30px;
  cursor: pointer;
}

.colCard2 {
  background: #ffffff;
  box-shadow: 0px 7px 20px rgba(40, 41, 61, 0.05);
  border-radius: 8px;
  padding: 30px;
  max-width: 385px;
  width: 40%;
  margin-right: 30px;
}

.colCard3 {
  background: #ffffff;
  box-shadow: 0px 7px 20px rgba(40, 41, 61, 0.05);
  border-radius: 8px;
  padding: 30px;
  max-width: 385px;
  width: 50%;
}

.heading {
  font-weight: 600;
  font-size: 24px;
  color: #373737;
  margin-bottom: 20px;
}

.rowHeadCheck {
  font-weight: 500;
  font-size: 14px;
  color: #14009b;
  margin-top: 5px;
  min-height: 34px;
  text-align: center;
}

.rowParaGreenCheck {
  margin-top: 7px;
  color: #37B256;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.rowParaRedCheck {
  margin-top: 7px;
  color: #E93017;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.rowParaOrangeCheck {
  margin-top: 7px;
  color: #E96D17;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}

.bgCircle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
  text-align: center;
}

.bgCircle .bgImage {
  position: relative;
  top: 27%;
}

.mapCardRowspanOther {
  font-weight: 600;
  font-size: 11px;
  color: #f56102;
  background: rgba(245, 97, 2, 0.07);
  border-radius: 7px;
  padding: 3px 10px;
}

.breadcrumbs {
  margin-bottom: 20px;
}

.noHover {
  margin-right: 10px;
  padding: 2px;
}

.hoverWrapper {
  margin-right: 10px;
  padding: 2px;
  border-radius: 5px;
}

.hoverWrapper:hover {
  background: #eff3f5;
  cursor: pointer;
}

.userList {
  padding: 40px;
}

.backBtn {
  background: none;
  border: none;
  color: #14009b;
  font-weight: 600;
  display: flex;
  cursor: pointer;
}

.listUsers {
  list-style: none;
  padding: 0;
  width: 50%;
  max-height: calc(100vh - 335px);
  overflow: auto;
}

.subHead {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 40px;
}

.listHead {
  display: flex;
  margin-bottom: 40px;
}

.listRow {
  display: flex;
  margin-bottom: 20px;
}

.listHeadCol1 {
  width: 80%;
  font-weight: 600;
  color: #14009b;
  font-size: 14px;
}

.listHeadCol2 {
  width: 20%;
  font-weight: 600;
  color: #14009b;
  font-size: 14px;
}

.listHeadCol3 {
  width: 20%;
  font-weight: 600;
  color: #14009b;
  font-size: 14px;
}

.listHeadRow1 {
  width: 80%;
  color: #3A3C40;
  font-size: 16px;
  font-weight: 500;
}

.listHeadRow2 {
  width: 20%;
  color: #3A3C40;
  font-size: 16px;
  font-weight: 500;
}

.listHeadRow3 {
  width: 20%;
  color: #3A3C40;
  font-size: 16px;
  font-weight: 500;
  margin-top: -10px;
  padding: 0;
}

.listHeadCol4 {
  width: 15%;
  font-weight: 600;
  color: #14009b;
  font-size: 14px;
}

.listHeadCol5 {
  width: 15%;
  font-weight: 600;
  color: #14009b;
  font-size: 14px;
}

.listHeadRow4 {
  width: 15%;
  color: #3A3C40;
  font-size: 16px;
  font-weight: 500;
}

.listHeadRow5 {
  width: 15%;
  color: #3A3C40;
  font-size: 16px;
  font-weight: 500;
}

.textStatus {
  color: #3A3C40;
  font-weight: 500;
  font-size: 16px;
  margin-left: 10px;
  display: inline-block
}

.userListImage {
  background: url('../../../assets/userList.svg');
  background-repeat: no-repeat;
  width: 80%;
  background-position: top;
  height: calc(100vh - 308px);
}

.myTeamHeading {
  font-size: 24px;
  font-weight: 600;
}

.graphWrapper {
  box-shadow: 0px 8px 24px rgb(0 0 0 / 15%);
  background: #F8F8F8 !important;
  border-radius: 8px;
  padding: 15px 20px;
}

.analysisText {
  color: #0E132F;
  font-size: 20px;
  margin-top: 10px;
}

.listReviews {
  list-style: none;
  padding: 0;
  width: 50%;
  max-height: calc(100vh - 335px);
  margin-left: 75px
}

@media (max-width: 1510px) {
  .colCardWrapHover {
    width: 29%;
  }

  .rowParaRedCheck {
    font-size: 17px;
  }

  .rowParaGreenCheck {
    font-size: 17px;
  }

  .rowParaOrangeCheck {
    font-size: 17px;
  }
}

@media (max-width: 1300px) {
  .rowHeadCheck {
    font-size: 12px;
  }
}

@media screen and (max-width: 799px) {

  .dashBoardContainer {
    padding: 80px 10px;
  }

  .colCard1 {
    width: 80%;
    text-align: center;
  }

  .colCardWrapHover {
    text-align: center;
    width: 80%;
    margin-bottom: 10px;
  }

  .colCardWrap {
    text-align: center;
    width: 80%;
    margin-bottom: 10px;
  }

  .topRowWrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .topRow {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@media screen and (min-width: 799px) and (max-width:1300px) {
  .colCard1 {
    width: 37%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .rowHeadCheck {
    font-size: 12px;
  }

  .topRow {
    display: flex;
    justify-content: flex-start;
    padding: 5px;
  }

  .topRowWrap {
    display: flex;
    justify-content: flex-start;
  }

  .heading {
    text-align: center;
  }

  .hoverWrapper {
    margin: auto;
  }

  .noHover {
    margin: auto;
  }

  .colCardWrap {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 42%;
  }

  .colCardWrapHover {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 42%;
  }
}

@media screen and (min-width: 1180px) and (max-width: 1300px) {
  .colCard1 {
    width: 25%;
  }

  .topRow {
    display: flex;
    justify-content: flex-start;
  }

  .colCardWrap {
    width: 30%;
  }

  .colCardWrapHover {
    width: 27%;
  }
}

@media screen and (max-width: 400px) {
  .colCardWrap {
    width: 90%;
  }

  .colCardWrapHover {
    width: 90%;
  }

  .colCard1 {
    width: 88%;
  }
}

@media screen and (max-width: 990px) {
  .listUsers {
    width: 80%;
    margin: auto;
  }

  .subHead {
    text-align: center;
  }
}