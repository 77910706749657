.fieldInput .MuiOutlinedInput-root {
  height: 42px;
  /* max-width: 490px; */
  color: #1f384c;
  background: #f5f5f7 !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}

.fieldInput .MuiOutlinedInput-notchedOutline {
  border-color: #e7e7e7 !important;
}

.fieldInput .MuiOutlinedInput-notchedOutline {
  border: 1px solid #babfc5;
  border-radius: 8px !important;
}

.fieldInput .Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f !important;
}

.fieldInput.search-add-peer {
  width: 100%;
}

.fieldInput.search-add-peer .MuiOutlinedInput-notchedOutline {
  border-color: #ccc !important;
}

.fieldInput.search-add-peer .MuiOutlinedInput-root.Mui-focused {
  background: #fff !important;
}