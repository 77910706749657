.surveyList {
  width: 100%;
  padding-top: 70px;
}

.surveyListMain {
  background: url("../../../assets/bgCircleLt.svg") left top no-repeat,
    url("../../../assets/shadowLt.svg") right top no-repeat,
    url("../../../assets/shadowRt.svg") left bottom no-repeat,
    url("../../../assets/bgCircleBlueRt.svg") right bottom no-repeat;
  width: 100%;
  height: calc(100vh - 70px);
}

.tabRow {
  width: 100%;
  border-bottom: 2px solid #babfc5;
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

.emptySpacer {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 40px;
}

.activetab {
  font-weight: 600;
  font-size: 14px;
  color: #14009b;
  padding: 0px 0px 20px 0px;
  cursor: pointer;
  border-bottom: 3px solid #14009b;
  margin: 0px 40px 0px 40px;
  position: relative;
  top: 2px;
}

.inActivetab {
  font-weight: 600;
  font-size: 14px;
  color: #82868c;
  padding: 0px 0px 20px 0px;
  cursor: pointer;
  margin: 0px 40px 0px 40px;
  position: relative;
  top: 2px;
}

.mainTable {
  background: #ffffff;
  box-shadow: 0px 7px 20px rgba(40, 41, 61, 0.05);
  border-radius: 8px;
  height: auto;
  width: 90%;
  margin: auto;
}

.noData {
  padding: 50px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: #14009b;
}

.tableWrapper {
  margin-top: 10px;
}

@media screen and (max-width: 1000px) {
  .surveyListMain {
    background: none;
    width: 100%;
    height: 100vh;
    background-color: transparent !important;
  }

  .mainTable {
    background: transparent;
    box-shadow: none;
    border-radius: 0px;
    height: auto;
    width: 100%;
    margin: auto;
    margin-top: 31px;
  }

  .activetab {
    font-size: 11px;
    margin: 0px 20px 0px 20px;
  }

  .inActivetab {
    font-size: 11px;
    margin: 0px 20px 0px 20px;
  }
}