.addQuestionsPage {
  width: 100%;
  height: auto;
  padding-top: 68px;
}

.tabRowOther {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.activetabOther {
  font-weight: 600;
  font-size: 14px;
  color: #14009b;
  padding: 0px 0px 20px 0px;
  cursor: pointer;
  border-bottom: 3px solid #14009b;
  margin: 0px 40px 0px 40px;
  position: relative;
  top: 2px;
}

.inActivetabOther {
  font-weight: 600;
  font-size: 14px;
  color: #82868c;
  padding: 0px 0px 20px 0px;
  cursor: pointer;
  margin: 0px 40px 0px 40px;
  position: relative;
  top: 2px;
}

.tabRow {
  width: 100%;
  /* border-bottom: 2px solid #babfc5; */
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
}

.activetab {
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  /* padding: 0px 0px 11px 0px; */
  margin: 0px 40px 0px 40px;
  width: 60%;
  align-self: center;
  text-align: center;
}

.addNewButton {
  font-weight: 600;
  font-size: 18px;
  color: #14009b;
  /* position: absolute;
  left: 4%;
  cursor: pointer; */
  cursor: pointer;
  width: 20%;
  align-self: center;
  background: none !important;
  box-shadow: none !important;
  border: none !important;
  padding: 0 !important;
  text-align: start;
}

.nextButton {
  font-weight: 600;
  font-size: 12px;
  color: white;
  /* position: absolute;
  right: 4%;
  cursor: pointer; */
  cursor: pointer;
  width: 20%;
  background: #f56102;
  padding: 8px 20px;
  width: 60px;
  text-align: center;
  border-radius: 8px;
}

.cancelButton {
  font-weight: 600;
  font-size: 12px;
  color: white;
  cursor: pointer;
  width: 20%;
  background: #f56102;
  padding: 8px 20px;
  width: 50px;
  text-align: center;
  border-radius: 8px;
  margin: -5px 0 0 20px;
}

.mainBox {
  padding: 30px;
  padding-top: 30px;
}

.addBlock {
  background: rgba(130, 134, 140, 0.07);
  border: 1px dashed #82868c;
  box-sizing: border-box;
  border-radius: 5px;
  text-align: center;
  padding: 25px;
  font-weight: 500;
  font-size: 13px;
  color: #1f384c;
  cursor: pointer;
  margin-bottom: 20px;
}

.addIconBox {
  position: absolute;
  margin-left: -19px;
}

.selectionBlock {
  background: url("../../../assets/bgCircleLt.svg") left top no-repeat,
    url("../../../assets/bgCircleBlueRt.svg") right bottom no-repeat;
  box-shadow: 0px 7px 15px rgba(40, 41, 61, 0.08);
  border-radius: 8px;
  width: inherit;
  height: auto;
  background-color: #ffffff;
  margin-top: 11px;
  padding: 47px 60px;
  margin-bottom: 20px;
}

.rowBlock {
  width: 100%;
  display: flex;
}

.startCard {
  box-shadow: 0px 7px 20px rgba(40, 41, 61, 0.08);
  border-radius: 8px;
  width: 450px;
  height: 100px;
  margin: auto;
  margin-top: 20px;
  display: flex;
}

.startCard:hover {
  box-shadow: 0px 7px 20px rgba(40, 41, 61, 0.15);
}

.leftposCard {
  border-radius: 8px;
  width: 9%;
}

.rightposCard {
  width: 91%;
  padding: 16px 22px;
  display: flex;
  background-color: #ffffff;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.rightCardHead {
  color: #000000;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 7px;
}

.rightCardPara {
  color: #9c9c9c;
  font-weight: 500;
  font-size: 13px;
}

.startRadio {
  text-align: right;
  justify-content: center;
  align-items: center;
  display: flex;
}

.questionFieldBox {
  background: url("../../../assets/bgBlueSmallRt.svg") right bottom no-repeat;
  background-color: #ffffff;
  box-shadow: 0px 2px 17px rgba(40, 41, 61, 0.2);
  border-radius: 8px;
  margin-bottom: 50px;
  display: flex;
}

.questionFieldHeading {
  padding: 20px 20px 0;
}

.questionFieldHeadingLt {}

.questionFieldHeadingRt {}

.rtDesDiv {
  display: flex;
  margin-bottom: 10px;
}

.questionFieldBody {
  padding: 20px;
  width: 80%;
}

.questionFieldFlex {
  display: flex;
}

.halfWidthBoxRt {
  width: 50%;
  margin-left: 10px;
}

.halfWidthBoxLt {
  width: 50%;
  margin-right: 10px;
}

.questionFieldTitle {
  color: #14009B;
  font-weight: 600;
  font-size: 12px;
}

.questionFieldRightBox {
  width: 100%;
  margin-top: 5px;
}

.questionFieldCheckBox {
  margin-top: 10px;
  font-weight: 600;
  font-size: 13px;
  color: #333333;
}

.questionFieldSave {
  color: #f56102;
  font-weight: 600;
  font-size: 15px;
  text-align: end;
}

.questionMultiFieldCheckBox {
  margin-top: 18px;
  font-weight: 600;
  font-size: 15px;
  color: #333333;
}

.questionMultiFieldSave {
  color: #f56102;
  font-weight: 600;
  font-size: 15px;
  text-align: end;
}

.questionMultiOptionBox {
  display: flex;
  padding-top: 20px;
  padding-bottom: 20px;
}

.questionMultiAddNew {
  padding: 12px;
  font-weight: 500;
  font-size: 13px;
  color: #1f384c;
  background: rgba(130, 134, 140, 0.07);
  border: 1px dashed #82868c;
  box-sizing: border-box;
  border-radius: 5px;
  width: 40%;
  height: fit-content;
  text-align: center;
  cursor: pointer;
}

.questionMultiTextField {
  width: 40%;
  height: 52px;
  margin-right: 10px;
}

.addCategoryModalButton {
  margin-top: 30px;
}

.questionSaveText {
  padding: 10px !important;
  color: white !important;
  background: #f56102 !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  font-size: 12px !important;
  line-height: 1 !important;
}

.questionPreviewEdit {
  position: absolute;
  right: 5%;
}

.questionYesNoRadio {
  display: flex !important;
  width: 100%;
}

.questionYesNoRadio label {
  width: 50% !important;
  margin-right: 0 !important;
}

.questionYesNoRadio label span {
  font-size: 16px !important;
  font-weight: 600 !important;
}

.previewQuestionTexts {
  font-weight: 600 !important;
  font-size: 13px !important;
  color: #000000 !important;
  width: 97%;
}

.errorMessage {
  text-align: center;
  font-size: 13px;
  color: #d32f2f;
}

.accordionMcq {
  display: flex;
}

.accordionMcqBox {
  background: rgba(130, 134, 140, 0.07);
  border: 1px solid #82868c;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 8px !important;
  width: 25%;
  margin-right: 20px;
  display: flex;
}

.accordionMcqAlpha {
  background: rgba(130, 134, 140, 0.07);
  border: 1px solid #82868c;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.accordionMcqText {
  font-weight: 500;
  font-size: 13px;
  color: #1f384c;
  width: 100%;
  align-self: center;
  padding: 0 25px;
}

.accordionMcqBoxActive {
  background: #f56102;
  border: 1px solid #f56102;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 12px !important;
  width: 25%;
  margin-right: 20px;
  display: flex;
}

.accordionMcqBoxActive .accordionMcqAlpha {
  background: rgba(130, 134, 140, 0.07);
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 5px;
  font-weight: 600;
  font-size: 16px;
  color: white;
  padding: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.accordionMcqBoxActive .accordionMcqText {
  font-weight: 500;
  font-size: 13px;
  color: white;
  width: 100%;
  align-self: center;
  padding: 0 25px;
}

.next {
  color: white !important;
  background-color: #f56102 !important;
  border-color: #f56102 !important;
}

.nextBlock {
  width: 20%;
  text-align: -webkit-right;
}

.questionCancelText {
  padding: 10px;
  color: #5c5959;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  margin-right: 20px;
}

.isDesktop {
  display: block;
}

.isMobile {
  display: none;
}

.isDesktopQues {
  display: block;
  width: 20%;
}

.isMobileQues {
  display: none;
}

.buttonBox {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 45px;
}

.secondaryButton {
  color: white !important;
  background-color: #c4c4c4 !important;
  border-color: #c4c4c4 !important;
  margin: 8px !important;
  width: 20%;
}

.scheduleWrapper {
  width: 30%;
  text-align: right;
  padding-right: 20px;
}

.createSurvey {
  color: white !important;
  background-color: #f56102 !important;
  border-color: #f56102 !important;
  width: 100%;
  margin: 8px !important;
  width: 55%;
}

.createSurvey:disabled {
  cursor: not-allowed !important;
  background-color: #f561028a !important;
  border-color: #f561028a !important;
}

.topDiv {
  padding: 0;
  width: 30%;
  font-weight: 600;
  font-size: 24px;
  color: #000000;
  margin: 10px 0 0 30px;
}

.tabWrapper {
  width: 60%;
  display: flex;
  justify-content: center;
}

.previewDiv {
  display: flex;
  justify-content: flex-end;
}

.previewDivCol {
  cursor: pointer;
}

.previewDivCol:hover {
  transform: scale(1.1);
}

.previewDivColum {
  width: 20%;
  text-align: end;
  align-self: center;
}


.questionFieldPreview {
  display: flex;
  padding-bottom: 3px;
  padding-top: 0px;
}

.surveyDetailSpan {
  font-weight: 600;
  font-size: 18px;
  color: #14009B;
  white-space: nowrap;
}

.surveyDetailSpanSmall {
  font-weight: 500;
  font-size: 14px;
  color: #1f384c;
  margin: 0 10px 0 0;
  width: 114px;
}

.surveyDetailExplain {
  font-size: 14px;
  color: #808080;
  font-weight: 400;
}

.explaination {
  display: flex;
  padding-bottom: 5px;
}

.surveyDetailimg {
  margin-left: 11px;
  margin-right: 10px;
  margin-top: 9px;
}

.surveyDetailQuestion {
  font-weight: 500;
  font-size: 15px;
  color: #1f384c;
  width: 100%;
  width: 80%;
  margin-top: 2px;
  line-height: 24px;
}

.addNewBtn {
  color: white !important;
  background-color: #F56102 !important;
  border-color: #F56102 !important;
  text-transform: capitalize !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  margin-right: 0px !important;
  margin-top: -41px !important;
  float: right;
  border: none !important;
  border-radius: 5px !important;
  padding: 5px 10px !important;
  cursor: pointer !important;
}

.addNewBtn:disabled {
  cursor: not-allowed;
}

.moreSummary {
  display: flex;
}

.typeSummary {
  color: #a1643a;
  margin: 0 10px 0 90px;
  font-size: 10px;
  font-weight: 500;
  padding: 3px 10px;
  background: #f561025e;
  border-radius: 3px;
  display: flex;
}

.catSummary {
  color: #FFF;
  margin: 0 10px 0 10px;
  font-size: 10px;
  font-weight: 500;
  padding: 3px 10px;
  background: #14009b85;
  border-radius: 3px;
  display: flex;
}

.subCatSummary {
  color: #FFF;
  margin: 0 10px;
  font-size: 10px;
  font-weight: 500;
  padding: 3px 10px;
  background: #14009ba6;
  border-radius: 3px;
}

.statusMessage {
  background: #f4f4f4;
  padding: 7px 15px;
  font-size: 13px;
  border-radius: 5px;
  margin-top: -5px;
  width: 500px;
}

@media screen and (max-width: 1100px) {

  .surveyDetailSpan {
    font-size: 18px;
  }

  .selectionBlock {
    padding: 47px;
  }

  .startCard {
    margin-right: 20px;
  }

  .questionFieldHeadingLt {
    width: 40%;
  }

  .questionFieldHeadingRt {
    width: 60%;
  }

  .surveyDetailQuestion {
    padding: 0px 0px;
    padding-bottom: 2px;
    font-size: 15px;
  }

}

@media screen and (max-width: 1000px) {

  .surveyDetailSpan {
    font-size: 15px;
  }

  .topDiv {
    padding: 10px;
    padding-bottom: 0;
  }

  .secondaryButton {
    width: 50%;
  }

  .buttonBox {
    padding-bottom: 30px;
    padding-top: 30px;
    width: 90%;
  }

  .isDesktop {
    display: none;
  }

  .isMobile {
    display: block;
  }

  .rowBlock {
    width: 100%;
    display: block;
  }

  .activetab {
    font-size: 11px;
  }

  .addNewButton {
    font-size: 11px;
    /* margin-top: 42px; */
  }

  .mainBox {
    padding: 10px;
  }

  .startCard {
    margin: auto;
    margin-bottom: 20px;
  }

  .accordionMcqBox {
    padding: 6px !important;
  }

  .accordionMcqText {
    font-size: 11px;
  }

  .surveyDetailimg {
    margin-left: 7px;
    margin-right: 15px;
    margin-top: 7px;
    width: 15px;
  }

  .surveyDetailQuestion {
    padding: 0px 0px;
    padding-bottom: 2px;
    font-size: 12px;
    align-self: center;
    width: 100%;
    margin-top: 0px;
  }

  .previewDivColum {
    width: 7%;
    text-align: end;
    align-self: center;
  }

  .typeSummary {
    margin-left: 0;
  }

}

@media screen and (max-width: 600px) {
  .isDesktopQues {
    display: none;
  }

  .isMobileQues {
    display: block;
  }

  .surveyDetailSpan {
    font-size: 12px;
  }

  .questionFieldFlex {
    display: flex;
  }

  .questionMultiOptionBox {
    display: block;
  }

  .questionMultiTextField {
    width: 100%;
  }

  .questionMultiAddNew {
    width: 100%;
  }

  .questionFieldTitle {
    font-size: 12px;
  }

  .questionFieldHeading {
    display: flex;
  }

  .questionFieldHeadingRt {
    justify-content: flex-end;
  }

  .questionFieldCheckBox {
    width: 100%;
  }

  .questionFieldSave {
    width: 100%;
  }

  .addQuestionsPage {
    height: auto;
  }

  .mainBox {
    padding: 15px;
    padding-top: 30px;
  }

  .selectionBlock {
    padding: 13px;
  }

  .rightCardHead {
    font-size: 12px;
  }

  .rightCardPara {
    font-size: 10px;
  }

  .startCard {
    width: 100%;
  }

  .topDiv {
    padding: 10px;
    padding-bottom: 0;
    margin: 10px 0 0;
    font-size: 18px;
  }

  .nextButton {
    padding: 8px 6px;
  }

  .addNewButton {
    width: 25%;
  }

  .nextBlock {
    width: 25%;
  }

  .activetab {
    width: 50%;
    margin: 0;
    text-align: center;
    padding: 0;
  }

  .previewDivColum {
    width: 12%;
    margin-top: 10px;
  }

  .accordionMcq {
    display: block;
  }

  .accordionMcqBox {
    width: 100%;
    margin-bottom: 10px;
  }

  .surveyDetailimg {
    margin-left: 5px;
    margin-right: 10px;
    width: 10px;
    margin-top: 1px;
  }

  .surveyDetailQuestion {
    font-size: 12px;
    padding: 0;
    line-height: 20px;
  }

  .leftposCard {
    width: 12%;
  }

  .rightposCard {
    width: 88%;
  }

  .questionFieldRightBox {
    margin-left: 0;
  }

  .questionFieldFlex {
    display: block;
  }

  .tabRowOther {
    margin-top: 0;
  }

  .scheduleWrapper {
    width: 40%;
  }

  .createSurvey {
    width: 100%;
  }

  .questionFieldBox {
    display: block;
  }

  .questionFieldHeadingLt {
    width: 100%;
  }

  .questionFieldBody {
    padding: 0;
    width: 100%;
    width: 89%;
    margin: 0 20px;
  }

  .halfWidthBoxRt {
    width: 100%;
    margin-left: 0;
    margin: 20px 0;
  }

  .halfWidthBoxLt {
    width: 100%;
    margin-right: 0;
    margin-top: 10px;
  }

  .statusMessage {
    width: 90%;
    margin: 0 auto;
  }
}

.ikigaiSummary {
  color: #FFF;
  margin: 0 10px 0 10px;
  font-size: 10px;
  font-weight: 500;
  padding: 3px 10px;
  background: #800080;
  border-radius: 3px;
  display: flex;
}

.addMoreKeyResults{
  width: auto;
  height: 17px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 17px;
  color: #14009B;
  border: none;
  background: none;
  cursor: pointer;
  margin-left: -83px;
}

@media screen and (max-width: 400px) {
  .leftposCard {
    width: 17%;
  }

  .rightposCard {
    width: 83%;
  }

}