.Mui-selected {
  background-color: white !important;
  color: #14009b !important;
  border-color: #14009b !important;
}

.MuiPaginationItem-previousNext {
  color: #14009b !important;
  border-color: #14009b !important;
}

.MuiInputBase-root {
  width: 100%;
}

.textInputField .MuiOutlinedInput-root {
  height: 42px;
  width: 100%;
  color: #1f384c;
  font-weight: 500;
  font-size: 13px;
}

.textInputField .MuiOutlinedInput-notchedOutline {
  border: 1px solid #1f384c !important;
}

.textInputField .MuiOutlinedInput-notchedOutline {
  border: 1px solid #1f384c;
  border-radius: 8px !important;
}

.textInputField .Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f !important;
}

.questionFieldText .MuiOutlinedInput-root {
  height: 42px;
  width: 100%;
  color: #1f384c;
  font-weight: 500;
  font-size: 13px;
}

.questionFieldTextArea .MuiOutlinedInput-root {
  height: auto;
}

.questionFieldText .MuiOutlinedInput-notchedOutline {
  border: 1px solid #82868c !important;
}

.questionFieldText .MuiOutlinedInput-notchedOutline {
  border: 1px solid #82868c;
  border-radius: 5px !important;
}

.questionFieldText .Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #d32f2f !important;
}

.addCategory .fieldInput .MuiOutlinedInput-root {
  max-width: 100% !important;
}

.MuiAccordion-root {
  margin-bottom: 20px !important;
  box-shadow: 0px 7px 15px rgba(40, 41, 61, 0.12) !important;
  border-radius: 8px !important;
  border: none !important;
  background: url("../../../assets/bgBlueSmallRt.svg") right bottom no-repeat !important;
  background-color: #ffffff !important;
}

.activeAccordion {
  border-top: 3px solid #14009b !important;
}

.inActiveAccordion {
  border-top: 3px solid #f56102 !important;
}

.MuiAccordion-root:before {
  background-color: transparent !important;
}

.accordianInput {
  padding: 0px 106px 16px !important;
}

.accordianInput .MuiInput-root:before {
  border-bottom: 2px solid rgba(20, 0, 155, 0.3) !important;
}

.accordianInput .MuiInput-root:after {
  border-bottom: 2px solid rgba(20, 0, 155, 0.3) !important;
}

.accordianInput .MuiInputBase-root {
  color: rgb(20 0 155 / 51%);
  font-weight: 500;
  font-size: 16px;
}

.checkInviteBox .MuiCheckbox-root.Mui-checked {
  color: #f56102;
}

.checkInviteBox .MuiCheckbox-root {
  color: #f56102 !important;
}

.toogleSwitch .MuiSwitch-switchBase.Mui-checked {
  color: #14009b !important;
}

.toogleSwitch .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
  background: rgba(20, 0, 155, 0.17) !important;
}

.toogleSwitch .MuiSwitch-switchBase {
  color: #82868c !important;
}

.toogleSwitch .MuiSwitch-track {
  background: #c5cfd7 !important;
}

button.response-table-count,
button.response-table-count:focus {
  margin-right: 5px !important;
  margin-bottom: 5px;
  display: inline-block !important;
  width: 31px !important;
  text-align: center !important;
  color: #222 !important;
  background: #ddd !important;
  cursor: pointer !important;
  border-radius: 5px !important;
  padding: 5px 0 !important;
  font-weight: 500;
  border: none !important;
}

button.response-table-count.last-dnk,
button.response-table-count.last-dnk:focus {
  width: auto !important;
  padding: 5px 12px !important;
}

button.response-table-count.active {
  background-color: #ff6100 !important;
  color: #fff !important;
}

.MuiDialogContent-root {
  padding: 20px 50px !important;
}

.participants-modal .MuiDialogContent-root {
  padding: 20px !important;
}

.participants-modal .MuiDialog-paper {
  padding: 20px 0 30px 0 !important;
}

.dateTimePicker .MuiOutlinedInput-root {
  padding: 10px 15px 10px 15px !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #1f384c !important;
  background-color: #dee5ec;
}

.dateTimePicker .MuiOutlinedInput-root input {
  padding: 2px 0 !important;
  display: flex;
  justify-content: space-between;
}

.dateTimePicker .MuiOutlinedInput-root fieldset {
  border: none !important;
}

.dateTimePicker input::-webkit-datetime-edit,
.dateTimePicker input::-webkit-inner-spin-button,
.dateTimePicker input::-webkit-clear-button {
  display: none;
}

.dateTimePicker.value input::-webkit-datetime-edit,
.dateTimePicker.value input::-webkit-inner-spin-button,
.dateTimePicker.value input::-webkit-clear-button {
  display: block;
}

.dateTimePicker input::before {
  content: 'Start Date';
  color: #82868c;
}

.dateTimePicker.value input::before {
  display: none;
}

.dateTimePicker.end input::before {
  content: 'End Date';
  color: #82868c;
}

.dateTimePicker input::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 7px;
  right: 7px;
  color: black;
  opacity: 1;
  font-size: 20px;
}

.MuiAccordionSummary-content {
  margin: 5px 0 10px !important;
}

textarea {
  font-size: 14px !important;
  font-weight: 500 !important;
}

.MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl {
  padding: 10px !important;
}

.Mui-disabled.MuiCheckbox-root {
  opacity: 0.5;
  cursor: not-allowed !important;
}

.MuiOutlinedInput-root.MuiInputBase-root.Mui-error .MuiOutlinedInput-notchedOutline {
  border: 1px solid #d32f2f !important;
}

.searchField {
  width: 33%;
}

.searchField .fieldInput {
  width: 90%;
  margin-top: 11px;
}

.searchField .MuiInputLabel-animated.MuiInputLabel-outlined {
  font-size: 12px !important;
  top: -5px
}

.searchField .MuiInputLabel-animated.MuiInputLabel-shrink {
  top: 2px;
  color: #333;
}

.searchField .MuiFormControl-root.MuiFormControl-fullWidth.MuiTextField-root .MuiOutlinedInput-root.MuiInputBase-root.MuiInputBase-colorPrimary.MuiInputBase-fullWidth.MuiInputBase-formControl {
  height: auto !important;
  padding: 0 !important;
  font-size: 12px !important;
}

.searchField input {
  padding: 10px !important;
}

.searchField .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium {
  margin-right: 7px;
}

input[type="date"]:after,
input[type="datetime-local"]:after {
  content: "\1F5D3";
  color: #555;
  font-size: 16px;
}

/* change color of symbol on hover */
input[type="date"]:hover:after,
input[type="datetime-local"]:hover:after {
  color: #bf1400;
}

/* make the native arrow invisible and stretch it over the whole field so you can click anywhere in the input field to trigger the native datepicker*/
input[type="date"]::-webkit-calendar-picker-indicator,
input[type="datetime-local"]::-webkit-calendar-picker-indicator {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  color: transparent;
  background: transparent;
}

/* adjust increase/decrease button */
input[type="date"]::-webkit-inner-spin-button,
input[type="datetime-local"]::-webkit-inner-spin-button {
  z-index: 1;
}

/* adjust clear button */
input[type="date"]::-webkit-clear-button,
input[type="datetime-local"]::-webkit-clear-button {
  z-index: 1;
}

.date-picker-wrapper-disabled .react-datepicker__input-container,
.date-picker-wrapper-disabled .react-datepicker__input-container input {
  cursor: not-allowed;
}

.date-picker-wrapper-disabled .react-datepicker__input-container {
  background: #ededed;
}

.date-picker-wrapper-disabled .react-datepicker__input-container input {
  color: #959595 !important;
  -webkit-text-fill-color: rgba(149, 149, 149);
}

.react-datepicker__input-container {
  padding: 10px 0 !important;
  background-color: #dee5ec;
  border-radius: 4px;
}

.react-datepicker__input-container {
  border: 1px solid #ccc !important;
}

.react-datepicker__input-container input {
  background: transparent;
  border: none !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #1f384c !important;
  padding: 0 0 0 19px;
  width: 100%;
}

.react-datepicker__input-container input:after {
  content: "sd";
  color: #555;
  font-size: 16px;
}

.react-datepicker__input-container input:focus {
  outline: none;
}

.react-datepicker-popper {
  z-index: 2 !important;
  width: 335px;
}

.date-label-custom {
  font-size: 11px !important;
  position: absolute !important;
  margin: 0 !important;
  line-height: 1 !important;
  left: 0px;
  padding: 0 2px !important;
  top: -14px
}

.date-label-custom-other {
  font-size: 12px !important;
  margin: 0 0 5px 2px !important;
  text-align: left;
  color: rgba(0, 0, 0, 0.6);
}

.genericTextArea ol,
.genericTextArea p {
  margin: 0;
}

button.MuiButton-outlinedPrimary:disabled {
  cursor: not-allowed !important;
  opacity: 0.7;
}

.DraftEditor-root {
  border: 1px solid #ccc;
  border-radius: 4px;
}

.DraftEditor-root:hover {
  border-color: rgba(0, 0, 0, .87) !important;
}

.DraftEditor-editorContainer {
  padding: 11px 10px;
  font-size: 16px;
  max-height: 150px;
  min-height: 65px;
  overflow: auto;
  font-weight: 500;
}

.public-DraftEditorPlaceholder-root {
  padding: 11px 10px;
  font-size: 13px;
  color: #bbb;
}

.public-DraftEditorPlaceholder-inner {
  display: none;
}

.public-DraftEditorPlaceholder-root::after {
  font-size: 12px;
  color: #bbb;
  font-style: normal;
  content: '1. 10% leads are accepted by sales \A 2. Double the product demos participants';
  left: 10px !important;
  white-space: pre;
}

.printBtn {
  color: #FF6100;
  border: 1px solid #FF6100;
  border-radius: 5px;
  padding: 5px 10px;
  margin: 8px 0 20px;
  height: 33px;
  cursor: pointer;
  background: none;
  display: flex;
  font-weight: 500;
}

.printBtn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.printBtn:not(:disabled):hover {
  background: #FF6100;
  color: #fff;
}

.printBtn .text {
  margin: 3px 5px 0 0;
}

@media (min-width: 1025px) {
  button.response-table-count.last-dnk:hover {
    background-color: #ff6100 !important;
    color: #fff !important;
  }
}

@media (max-width: 767px) {

  button.response-table-count,
  button.response-table-count:focus {
    margin: 7px !important;
    width: 50px !important;
    padding: 15px 0 !important;
  }

  button.response-table-count.last-dnk,
  button.response-table-count.last-dnk:focus {
    padding: 15px 22px !important;
  }
}

@media screen and (max-width: 600px) {
  .accordianInput {
    padding: 0px 16px 16px !important;
  }

  textarea {
    font-size: 16px;
  }

  button.response-table-count,
  button.response-table-count:focus {
    margin: 3px !important;
    width: 30px !important;
    padding: 6px 0 !important;
  }

  button.response-table-count.last-dnk,
  button.response-table-count.last-dnk:focus {
    padding: 6px 22px !important;
  }
}