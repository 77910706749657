.review-minutes-wrapper {
  margin-top: 70px;
}

.review-minutes-wrapper .top-heading {
  color: #000;
  font-weight: 600;
  font-size: 24px;
  margin: 20px 0 0 30px;
}

.ql-snow.ql-toolbar {
  background: rgba(218, 218, 218, 0.7);
  border-radius: 15px 15px 0px 0px;
}

.ql-snow.ql-toolbar button.ql-hr {
  font-size: 16px;
  font-weight: 600;
}

.ql-snow.ql-toolbar button.ql-hr:after {
  content: 'Hr';
  font-weight: 500;
}

.ql-editor {
  height: 50vh !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Roboto"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Roboto"]::before {
  font-family: "Roboto", cursive;
  ;
  content: "Roboto" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Raleway"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Raleway"]::before {
  font-family: "Raleway", cursive;
  ;
  content: "Raleway" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Montserrat"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Montserrat"]::before {
  font-family: "Montserrat", cursive;
  ;
  content: "Montserrat" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Lato"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Lato"]::before {
  font-family: "Lato", cursive;
  ;
  content: "Lato" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Rubik"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Rubik"]::before {
  font-family: "Rubik", cursive;
  ;
  content: "Rubik" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Sans-serif"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Sans-serif"]::before {
  font-family: "sans-serif", cursive;
  ;
  content: "Sans serif" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Calibri"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Calibri"]::before {
  font-family: "Calibri", arial;
  ;
  content: "Calibri" !important;
}

.ql-picker.ql-font .ql-picker-label[data-value="Helvetica"]::before,
.ql-picker.ql-font .ql-picker-item[data-value="Helvetica"]::before {
  font-family: "Helvetica", cursive;
  ;
  content: "Helvetica" !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="10px"]::before {
  content: "10px" !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px" !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="13px"]::before {
  content: "13px" !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px" !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16px" !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="18px"]::before {
  content: "18px" !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px" !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24px" !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="26px"]::before {
  content: "26px" !important;
}

.ql-picker.ql-size .ql-picker-item[data-value="32px"]::before {
  content: "32px" !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before {
  content: '13px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value='10px']::before {
  content: '10px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value='12px']::before {
  content: '12px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value='13px']::before {
  content: '13px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value='14px']::before {
  content: '14px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value='16px']::before {
  content: '16px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value='18px']::before {
  content: '18px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value='20px']::before {
  content: '20px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value='24px']::before {
  content: '24px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value='26px']::before {
  content: '26px' !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-label.ql-active[data-value='32px']::before {
  content: '32px' !important;
}

.ql-snow .ql-editor a {
  text-decoration: inherit;
}

/* Set content font-families */
.ql-font-Roboto {
  font-family: "Roboto" !important;
}

.ql-font-Raleway {
  font-family: "Raleway" !important;
}

.ql-font-Montserrat {
  font-family: "Montserrat" !important;
}

.ql-font-Lato {
  font-family: "Lato" !important;
}

.ql-font-Rubik {
  font-family: "Rubik" !important;
}

.ql-font-Sans-serif {
  font-family: "sans-serif", cursive !important;
}

.ql-toolbar.ql-snow {
  border: 1px solid #dadada !important;
}

.ql-container.ql-snow {
  border: 1px solid #dadada !important;
  border-top: 0 !important;
  border-radius: 0 0 15px 15px;
  min-height: 50vh;
}

.ql-snow .ql-picker.ql-size {
  width: 60px !important;
}

.ql-snow .ql-picker.ql-font {
  width: 100px !important;
}

.sub-title {
  font-weight: 600;
  font-size: 20px;
  color: #000;
}

.record-minutes {
  margin: 0px 40px 20px 0;
}

.review-dropdown {
  width: 33%;
  min-width: 140px;
}

.review-dropdown.dropDownField .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
  top: 2px;
}

.review-dropdown.dropDownField .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiInputLabel-shrink {
  color: #888;
  left: -18px;
  font-size: 15px;
}

.performance-dropdown {
  width: auto;
  min-width: 165px;
  margin-right: 5px;
}

.performance-dropdown.dropDownField .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
  top: 3px;
  left: -3px;
}

.performance-dropdown.dropDownField .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined.MuiInputLabel-shrink {
  color: #888;
  left: -18px;
  font-size: 15px;
}

.back-btn-radar {
  background: none;
  color: #14009b;
  border: none;
  margin-left: 10px;
  font-weight: 600;
  display: flex;
  cursor: pointer;
}

.back-radar-text {
  margin-left: 5px;
  padding-top: 3px;
  font-size: 15px;
}

.radar-block {
  background: rgb(255, 255, 255);
  box-shadow: rgb(0 0 0 / 5%) 0px 0px 24px;
  border-radius: 8px;
  padding: 20px 25px;
  margin: 2%;
  width: 80%;
  border: 1px solid #ebebeb;
}

.radar-block .heading-text {
  font-weight: 500;
  font-size: 16px;
  color: #1f384c;
  margin-bottom: 15px;
}

.radar-block .key-results {
  font-weight: 500;
  font-size: 14px;
  color: #1f384c;
  margin-top: 5px;
}

.radar-block .cat-area {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  padding: 5px 10px;
  background: rgba(20, 0, 155, 0.52);
  display: flex;
  height: fit-content;
  border-radius: 3px;
  font-weight: 500;
  margin-right: 30px;
  display: flex;
  line-height: 1;
}

.radar-block .top-block {
  margin-right: 20px;
  margin-bottom: 15px;
}

.radar-block .small-text {
  color: #1F384C;
  font-weight: 500;
  font-size: 11px;
}

.radar-block .number-area1 {
  padding: 5px 10px;
  background: #FBC5A2;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
}

.radar-block .number-area2 {
  padding: 5px 10px;
  background: #1F384C;
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  margin-top: 5px;
  color: #fff;
}

.radar-block .peer-name {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin-top: 5px;
}

.radar-block .peer-value {
  font-size: 13px;
  font-weight: 600;
  color: #000;
  margin-top: 5px;
  text-align: center;
}

.performance-label {
  background: #ccc;
  padding: 5px 10px;
  margin: -28px 0px 0px 353px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: 500;
  height: 15px;
  font-style: italic;
  width: 25%;
}

.goal-title-dropdown {
  width: 40%;
  margin: 2%;
}

.finalized-text {
  position: absolute;
  font-size: 12px;
  color: #008a00;
}

.question-back-btn {
  color: #ff6100;
  font-size: 13px;
  cursor: pointer;
  padding: 15px 30px 0 0;
}

.ir-agility-detail {
  display: flex !important;
  justify-content: flex-end;
  padding-top: 10px;
}

span.ir-peer-filled {
  font-size: 18px;
  font-weight: 500;
}

span.ir-peer-all {
  font-size: 13px;
}

span.ir-seprater {
  vertical-align: middle;
  width: 1px;
  background: #ddd;
  height: 30px;
  display: inline-flex;
  margin: 0 17px;
}

.energy-question-wrapper .tip-top-container {
  padding: 10px !important;
}

.new-energy-meter-wrapper .energy-question-wrapper .ui.pointing.secondary.menu a {
  color: #140098
}

.new-energy-meter-wrapper .ui.breadcrumb .section {
  font-size: 20px !important;
}

.new-energy-meter-wrapper .ui.breadcrumb div.section {
  color: #ff6100;
}

.new-energy-meter-wrapper .ir-agility-score.left {
  border-bottom-right-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.new-energy-meter-wrapper .meter-participants {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.energy-question-wrapper .tip-top-container {
  padding: 10px !important;
}

.new-energy-meter-wrapper .tip-top.left {
  width: 70%;
  clear: both;
}

.new-energy-meter-wrapper .tip-top.right {
  width: 70%;
  float: right;
}

.new-energy-meter-wrapper .tip-top.left .question-user-name {
  margin-left: 10px;
  color: #ff6100;
  display: flex;
}

.new-energy-meter-wrapper .tip-top.right .question-user-name {
  text-align: right;
  margin-right: 20px;
  color: #140098;
  display: flex;
}

.new-energy-meter-wrapper .tip-top.right .triangle-tip:before {
  left: 32px;
  right: auto;
}

.new-energy-meter-wrapper .tip-top.right .triangle-tip:after {
  left: 32px;
  right: auto;
}

.new-energy-meter-wrapper .energy-question-wrapper .ui.pointing.secondary.menu a {
  color: #140098
}

@media (max-width: 767px) {
  .new-energy-meter-wrapper .energy-question-wrapper .ui.pointing.secondary.menu a {
    padding: 10px;
    font-size: 13px;
  }
}

@media (max-width: 767px) {

  .new-energy-meter-wrapper .ui.breadcrumb a,
  .new-energy-meter-wrapper .ui.breadcrumb div {
    line-height: 30px;
  }
}

.new-energy-meter-wrapper .header-menu-bar.ui.pointing.secondary.menu a {
  margin-right: 30px;
}


@media (min-width: 1500px) {

  .new-energy-meter-wrapper .detailpeerlist-filled ul,
  .new-energy-meter-wrapper .detailpeerlist-notfilled ul {
    height: 55vh;
  }
}

@media (min-width: 1550px) {

  .new-energy-meter-wrapper .detailpeerlist-filled ul,
  .new-energy-meter-wrapper .detailpeerlist-notfilled ul {
    height: 58vh;
  }
}

@media (min-width: 1800px) {

  .new-energy-meter-wrapper .detailpeerlist-filled ul,
  .new-energy-meter-wrapper .detailpeerlist-notfilled ul {
    height: 70vh;
  }
}

.triangle-tip {
  position: relative;
}

.triangle-tip:before {
  content: '';
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #F8F8F8;
  position: absolute;
  top: -8px;
  left: 32px;
  z-index: 1;
}

.triangle-tip:after {
  content: "";
  position: absolute;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid #DDDDDD;
  top: -10px;
  left: 32px;
}

.tip-top-container {
  list-style: none;
  font-size: 16px;
  margin: 0;
  border: 1px solid #DDDDDD;
  padding: 10px 15px !important;
  left: -10px;
  top: 24px;
  text-align: left;
  font-style: normal;
  background: #F8F8F8;
  border-radius: 4px;
}

.energy-question-wrapper .tip-top-container {
  padding: 10px !important;
}

.tip-top-cat {
  color: #555555;
  font-size: 14px;
  margin-bottom: 10px;
}

.tip-top-cat span {
  margin-right: 5px;
}

.tip-top-sub-text {
  font-size: 12px;
}

.tip-top-text {
  display: flex;
}

.tip-top-text .nav-logout {
  margin-left: 10px;
  width: 100%;
  font-size: 14px;
}

.verticle-line-color {
  width: 3px;
}

.verticle-line-color.tip-line {
  background: #24B577;
}

.verticle-line-color.top-line {
  background: #DD269C;
}

.verticle-line-color.tip-line {
  background: #24B577;
}

.verticle-line-color.top-line {
  background: #DD269C;
}

.generic-next,
.generic-previous {
  color: #ff6100;
  font-size: 14px;
  background: none;
  border: none;
}

.generic-next i,
.generic-previous i {
  font-size: 50px !important;
  width: 28px;
  margin: 0;
}

.generic-next:hover,
.generic-previous:hover {
  opacity: 1 !important;
  font-weight: 500;
}

.saveButton.small {
  margin: 0 10px 0 0 !important;
  padding: 3px 20px !important;
  font-size: 12px !important;
  width: 100px;
}

.saveButton.small:disabled:hover {
  opacity: 0.3 !important;
}

.saveButton.small:focus {
  font-weight: 600;
}

@media (max-width: 767px) {

  .generic-next,
  .generic-previous {
    top: 45%;
    height: 75px;
  }
}

.macro-view {
  list-style: none;
  padding: 8px 12px;
  border: 1px solid #DDDDDD;
  font-size: 12px;
}

.macro-view:not(:first-child) {
  border-left: none;
}

.macro-view.active {
  background-color: #f56102;
  border-color: #f56102;
  background-image: linear-gradient(117deg, #f56102 30%, #f56102 79%);
  color: #fff;
  font-weight: 600;
}

ul .macro-view {
  cursor: pointer;
}

ul .macro-view.active {
  cursor: default;
}

.generic-previous:disabled,
.generic-next:disabled {
  cursor: not-allowed !important;
  opacity: 0.3 !important;

}

.generic-previous:hover,
.generic-next:hover {
  cursor: pointer !important;
}

.question-container {
  padding: 0 5px 0 30px;
  max-height: 73vh;
  overflow: auto;
}

.review-minutes-main {
  background: rgb(255, 255, 255);
  box-shadow: rgb(40 41 61 / 5%) 0px 7px 20px;
  border-radius: 8px;
  height: 25%;
  width: 90%;
  margin: 1% auto auto;
  border: 1px groove rgb(204, 204, 204);
}

.minutes-review {
  margin-top: 1%;
  margin-left: 3%;
  padding: 5px;
}

.minutes-reviewer-name {
  justify-content: flex-start;
  color: rgb(31, 56, 76);
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
}

.minutes-dateTime {
  font-weight: 500;
  margin-left: 67%;
  font-size: 15px;
  position: absolute;
  color: rgb(97, 100, 105);
}

.review-minutes-data {
  margin-left: 3%;
  margin-right: 10%;
  /* max-height: 111px;
  overflow-y: scroll; */
}

.minutes-created-by {
  justify-content: right;
  display: flex;
  margin-right: 3%;
  color: rgb(31, 56, 76);
  text-transform: capitalize;
  font-weight: 500;
  font-size: 16px;
}

.minutes-button:hover {
  cursor: pointer !important;
}

.reviewsaveButton {
  color: white !important;
  background-color: #f56102 !important;
  border-color: #f56102 !important;
  width: 30%;
}

.minutesActiveLine {
  width: 50%;
  /* margin-left: -21%; */
  margin-top: 2%;
  border-radius: 100px;
  height: 3px;
  border: 1px solid #f56102;
  background: #f56102;
}

.minutesInActiveLine {
  width: 50%;
  /* margin-left: -154px; */
  margin-top: 2%;
  border-radius: 100px;
  height: 3px;
  border: 1px solid #c4c4c4;
  background: #c4c4c4;
}