.sendSurveyPage {
  width: 100%;
  height: auto;
  padding-top: 68px;
}

.sendSurveyInnerPage {
  /* background: url("../../../assets/bgCircleLt.svg") left top no-repeat,
      url("../../../assets/bgCircleBlueRt.svg") right bottom no-repeat; */
}

.sendSurveyHead {
  display: flex;
  padding: 40px 30px 0 30px;
}

.sendSurveyHeadCol1 {
  width: 40%;
}

.sendSurveyHeadCol2 {
  width: 60%;
  /* text-align: end; */
}

.sendSurveyCard {
  background: url("../../../assets/orangeBg.svg") left top no-repeat;
  box-shadow: 0px 7px 20px rgba(40, 41, 61, 0.08);
  border-radius: 8px;
  margin-left: 50px;
  height: auto;
  /* overflow-y: scroll; */
}

.sendSurveyCardHead {
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  color: #000000;
  padding-top: 19px;
}

.sendSurveyheadText {
  font-weight: 600;
  font-size: 20px;
  color: #000000;
}

.sendSurveyheadPara {
  font-weight: 500;
  font-size: 13px;
  color: #82868c;
}

.mapCardBox {
  text-align: left;
  display: grid;
  margin-top: 20px;
  overflow: auto;
  min-height: 200px;
  height: calc(100vh - 400px)
}

.checkedBoxCol {
  display: flex;
  padding-top: 25px;
  /* padding-bottom: 30px; */
}

.mapCards {
  color: white;
  padding: 1rem;
  display: flex;
  height: fit-content;
}

.cardName {
  font-weight: 500;
  font-size: 12px;
  color: #3a3c40;
  margin-top: 6px;
}

.cardId {
  font-weight: 500;
  font-size: 12px;
  color: #82868c;
  text-align: left;
  font-style: italic;
}

.cardEmail {
  font-weight: 500;
  font-size: 11px;
  color: #616469;
  text-align: left;
}

.cardBox {
  display: flex;
}

.cardBoxCircle {
  background: #ff9777;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  text-align: center;
  margin-right: 10px;
}

.cardBoxCircleText {
  position: relative;
  text-transform: uppercase;
  top: 25%;
}

.checkBlock {
  padding-top: 10px;
  margin-right: 10px;
}

.next {
  color: white !important;
  background-color: #f56102 !important;
  border-color: #f56102 !important;
  width: 20%;
}

.topDiv {
  display: flex;
  margin: 20px 0 30px;
}

.dropDownDiv {
  width: 50%;
  margin-top: 20px;
}

.toggleText {
  width: 80%;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  align-self: center;
}

.toggleSwitch {
  width: 20%;
  text-align: end;
}

.checkedBox {
  margin-top: 10px;
}

.checkedBoxHead {
  font-weight: 500;
  font-size: 14px;
  color: #000000;
}

.datePicker {
  width: 50%;
  margin-right: 10px;
}

.selectPicker {
  width: 50%;
  margin-right: 8px;
  position: relative;
}

.selectPickerDate {
  width: 50%;
  margin-right: 10px;
  position: relative;
}

.modifyPeer {
  font-weight: 600;
  font-size: 14px;
  color: #14009b;
  padding: 20px;
  text-align: center;
}

.mapCardRow {
  display: flex;
  padding: 20px;
}

.mapCardColumn1 {
  font-weight: 600;
  font-size: 12px;
  color: #14009b;
  width: 40%;
}

.mapCardColumn2 {
  font-weight: 600;
  font-size: 12px;
  color: #14009b;
  width: 20%;
  text-align: center;
}

.mapCardColumn3 {
  font-weight: 600;
  font-size: 12px;
  color: #14009b;
  width: 20%;
  text-align: center;
}

.mapCardColumn4 {
  font-weight: 600;
  font-size: 12px;
  color: #14009b;
  width: 20%;
  text-align: center;
}

.mapCardRow2 {
  text-align: left;
  height: calc(100vh - 470px);
  min-height: 200px;
  overflow: scroll;
}

.mapCardRowCol1 {
  width: 40%;
}

.mapCardRowCol2 {
  width: 20%;
  text-align: center;
  padding-top: 7px;
}

.mapCardRowCol3 {
  width: 20%;
  text-align: center;
  padding-top: 7px;
}

.mapCardRowCol4 {
  width: 20%;
  text-align: center;
  padding-top: 7px;
}

.reportButton {
  background: #14009b !important;
  color: white !important;
  width: 75%;
  font-size: 12px !important;
  padding: 5px !important;
}

.mapCardRowspan {
  font-weight: 600;
  font-size: 12px;
  color: #f56102;
  background: rgba(245, 97, 2, 0.07);
  border-radius: 100px;
  padding: 10px 20px;
}

.mapCardRowspanOther {
  font-weight: 600;
  font-size: 11px;
  color: #f56102;
  background: rgba(245, 97, 2, 0.07);
  border-radius: 100px;
  padding: 3px 10px;
}

.mapCardDate {
  font-size: 12px;
  color: #000;
}

.isDesktop {
  display: block;
}

.isMobile {
  display: none;
}

.buttonBox {
  margin: 30px auto 0;
  display: flex;
  justify-content: center;
}

.secondaryButton {
  color: white !important;
  background-color: #c4c4c4 !important;
  border-color: #c4c4c4 !important;
  margin: 8px !important;
  width: 20%;
}

.createSurvey {
  color: white !important;
  background-color: #f56102 !important;
  border-color: #f56102 !important;
  width: 20%;
  margin: 8px !important;
}

.tabRow {
  width: 57%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.activetab {
  font-weight: 600;
  font-size: 14px;
  color: #14009b;
  padding: 0px 0px 10px 0px;
  cursor: pointer;
  border-bottom: 3px solid #14009b;
  margin: 0px 20px 0px 20px;
  position: relative;
  top: 2px;
}

.inActivetab {
  font-weight: 600;
  font-size: 14px;
  color: #82868c;
  padding: 0px 0px 10px 0px;
  cursor: pointer;
  margin: 0px 20px 0px 20px;
  position: relative;
  top: 2px;
}

@media (min-width: 600px) {
  .mapCardBox {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 1100px) {
  .datePicker {
    width: 50%;
  }

  .selectPickerDate {
    width: 50%;
  }

  .mapCardRowspan {
    font-size: 9px;
  }
}

@media screen and (max-width: 1000px) {
  .createSurvey {
    width: 50%;
  }

  .secondaryButton {
    width: 50%;
  }

  .buttonBox {
    padding-bottom: 30px;
    padding-top: 30px;
    width: 90%;
  }

  .isDesktop {
    display: none;
  }

  .isMobile {
    display: block;
  }

  .next {
    width: 40%;
  }

  .mapCardBox {
    padding: 30px 10px 0 10px;
  }

  .sendSurveyHead {
    display: block;
    padding: 30px 10px 0 10px;
  }

  .sendSurveyHeadCol1 {
    width: 100%;
  }

  .sendSurveyHeadCol2 {
    width: 100%;
    /* text-align: end; */
  }

  .sendSurveyMobileHead {
    padding: 30px 10px 0 10px;
  }

  .sendSurveyHeadRow {
    display: flex;
  }

  .sendSurveyheadText {
    font-weight: 600;
    font-size: 13px;
    color: #3a3c40;
  }

  .sendSurveyheadPara {
    font-weight: 500;
    font-size: 12px;
    color: #82868c;
    margin-top: 15px;
  }

  .sendSurveyCard {
    margin-left: 0;
    margin-top: 20px;
    /* height: auto; */
  }
}

@media screen and (max-width: 600px) {
  .mapCardColumn1 {
    font-size: 10px;
  }

  .mapCardColumn2 {
    font-size: 10px;
  }

  .mapCardColumn3 {
    font-size: 10px;
  }

  .reportButton {
    font-size: 10px !important;
  }

  .selectPickerDate {
    width: 100%;
    margin: 0 0 25px;
  }

  .toggleText {
    font-size: 13px;
  }

  .mapCardBox {
    grid-template-columns: repeat(2, 1fr);
    padding-bottom: 10px;
  }

  .mapCards {
    padding: 5px;
  }

  .cardName {
    font-size: 10px;
  }

  .sendSurveyCardHead {
    font-size: 13px;
  }

  .cardId {
    font-size: 10px;
  }

  .isDesktop {
    display: none;
  }

  .isMobile {
    display: block;
  }

  .inActiveLine {
    height: 1px;
  }

  .activeLine {
    height: 1px;
  }

  .checkedBoxCol {
    display: block;
  }

  .datePicker {
    width: 100%;
    margin-bottom: 10px;
  }

  .selectPicker {
    width: 100%;
    margin-bottom: 25px;
  }

  .mapCardRowCol2 {
    padding-top: 2px;
  }
}

@media screen and (max-width: 400px) {
  .mapCardRowspan {
    font-size: 6px;
  }
}

@media screen and (max-width: 350px) {}