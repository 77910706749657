.inviteParticipantsPage {
  width: 100%;
  height: auto;
  padding-top: 68px;
}

.inviteParticipantsInnerPage {
  /* background: url("../../../assets/bgCircleLt.svg") left top no-repeat,
    url("../../../assets/bgCircleBlueRt.svg") right bottom no-repeat; */
}

.inviteParticipantHead {
  display: flex;
  padding: 40px 30px 0 30px;
}

.inviteParticipantHeadCol1 {
  width: 50%;
}

.inviteParticipantHeadCol2 {
  width: 50%;
  text-align: end;
}

.inviteParticipantheadText {
  font-weight: 600;
  font-size: 20px;
  color: #000000;
}

.inviteParticipantheadPara {
  font-weight: 500;
  font-size: 13px;
  color: #82868c;
}

.addExternal {
  color: white !important;
  background-color: #14009b !important;
  border-color: #14009b !important;
}

.isDesktop {
  display: block;
}

.isMobile {
  display: none;
}

.mapCardBox {
  padding: 30px 30px 0 30px;
  display: grid;
  grid-gap: 1rem;
}

.mapCardsOther {
  background: #f5f5f7;
  padding: 1rem;
  display: flex;
  box-shadow: 7px 7px 10px #ddd;
}

.mapCards {
  color: white;
  padding: 1rem;
  display: flex;
}

.cardName {
  font-weight: 500;
  font-size: 12px;
  color: #3a3c40;
  margin-top: 0px;
  text-align: left;
  max-width: 178px;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 6px;
}

.cardId {
  font-weight: 500;
  font-size: 12px;
  color: #82868c;
  text-align: left;
  font-style: italic;
}

.cardEmail {
  font-weight: 500;
  font-size: 11px;
  color: #616469;
  text-align: left;
}

.cardBox {
  display: flex;
}

.cardBoxCircle {
  background: #ff9777;
  border-radius: 20px;
  width: 40px;
  height: 40px;
  text-align: center;
  margin-right: 10px;
}

.cardBoxCircleText {
  position: relative;
  top: 25%;
  text-transform: uppercase;
}

.checkBlock {
  padding-top: 10px;
  margin-right: 10px;
}

.next {
  color: white !important;
  background-color: #f56102 !important;
  border-color: #f56102 !important;
  width: 20%;
}

.buttonBox {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  padding-bottom: 45px;
}

.secondaryButton {
  color: white !important;
  background-color: #c4c4c4 !important;
  border-color: #c4c4c4 !important;
  margin: 8px 16px 8px 0 !important;
  width: 20%;
}

.createSurvey {
  color: white !important;
  background-color: #f56102 !important;
  border-color: #f56102 !important;
  width: 100%;
  margin: 8px 0 !important;
}

.searchContent {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.searchMapContent {
  color: white;
  padding: 1rem;
  display: flex;
  padding-left: 0;
  padding-right: 0;
}

.popUpList {
  height: 250px;
  overflow-y: scroll;
  position: absolute;
  background: #fff;
  top: 60px;
  padding: 0 2px 0 10px;
  z-index: 1;
  box-shadow: 0px 3px 20px #e1e1e1;
  border: 1px solid #efefef;
  min-width: 300px;
}

.noData {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  color: #15009b62;
}

.cardBoxPop {
  display: flex;
  width: 50%;
}

.cardBoxright {
  width: 50%;
  text-align: end;
}

.peerAddBut {
  color: white !important;
  background-color: #14009B !important;
  border-color: #14009B !important;
  /* width: 30%; */
  margin: 2px !important;
  font-size: 10px !important;
  padding: 5px !important;
}

.peerAddedText {
  color: #14009B;
  display: inline-flex;
  font-size: 10px !important;
  padding: 5px 3px 5px 2px;
  margin: 2px 3px 2px 0 !important;
  border: 1px solid #14009B;
  border-radius: 2px;
}

.mapCardRowspanOther {
  font-weight: 600;
  font-size: 11px;
  color: #f56102;
  background: rgba(245, 97, 2, 0.07);
  border-radius: 100px;
  padding: 3px 10px;
}

@media (min-width: 600px) {
  .mapCardBox {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 900px) {
  .mapCardBox {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (max-width: 1000px) {
  .createSurvey {
    width: 100%;
  }

  .secondaryButton {
    width: 50%;
  }

  .buttonBox {
    padding-bottom: 30px;
    padding-top: 30px;
    width: 90%;
  }

  .isDesktop {
    display: none;
  }

  .isMobile {
    display: block;
  }

  .next {
    width: 40%;
  }

  .mapCardBox {
    padding: 30px 10px 0 10px;
  }

  .inviteParticipantMobileHead {
    padding: 30px 10px 0 10px;
  }

  .inviteParticipantHeadRow {
    display: flex;
  }

  .addExternalText {
    display: flex;
    width: 50%;
    font-weight: 600;
    font-size: 12px;
    color: #14009b;
    justify-content: flex-end;
  }

  .inviteParticipantheadText {
    width: 50%;
    font-weight: 600;
    font-size: 13px;
    color: #3a3c40;
  }

  .inviteParticipantheadPara {
    font-weight: 500;
    font-size: 12px;
    color: #82868c;
    margin-top: 15px;
  }
}

@media screen and (max-width: 600px) {
  .isDesktop {
    display: none;
  }

  .isMobile {
    display: block;
  }

  .searchMapContent {
    display: block
  }

  .cardBoxPop {
    width: 100%;
  }

  .cardBoxright {
    width: 100%;
    text-align: end;
    margin-top: 10px;
  }
}

@media screen and (max-width: 400px) {
  .cardName {
    word-break: break-all;
  }

  .cardId {
    word-break: break-all;
  }
}

@media screen and (max-width: 350px) {}

.selfEvaluationText {
  font-weight: 500;
  font-size: 14px;
  color: #3a3c40;
  margin-top: 7px;
  text-align: left;
  margin-right: 3%;
}