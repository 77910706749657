.questionTag{
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 600;
   font-size: 20px;
   line-height: 24px;
   color: #000000;
}

.ikigaiQuestionDetail{
   margin-left: 11px;
   margin-right: 10px;
   /* margin-top: 9px; */
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 600;
   font-size: 20px;
   line-height: 24px;
   color: #000000;
}

.saveButton{
   color: white !important;
   background-color: #f56102 !important;
   border-color: #f56102 !important;
   width: 37px;
   margin-left: 98% !important;
   width: 92px;
}

.detailsDescription{
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 500;
   font-size: 14px;
   line-height: 20px;
   color: #9C9C9C;
   margin-left: 9px;
}

.addMore{
   width: auto;
   height: 17px;
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 600;
   font-size: 14px;
   line-height: 17px;
   color: #14009B;
   border: none;
   background: none;
   cursor: pointer;
   margin-left: -83px;
}

/* textarea {
   font-size: 20px !important;
   color: #000 !important;
 } */


/* Popup style */
.popupBox {
   position: fixed;
   background: #00000050;
   width: 100%;
   height: 100vh;
   top: 0;
   left: 0;
 }
  
 .box {
   position: relative;
   width: 70%;
   margin: 0 auto;
   height: auto;
   max-height: 70vh;
   margin-top: calc(100vh - 85vh - 20px);
   background: #fff;
   border-radius: 4px;
   padding: 20px;
   border: 1px solid #999;
   overflow: auto;
 }

 .css-3bmhjh-MuiPaper-root-MuiPopover-paper{
   max-width: 600px !important;
 }

 .onHover{
   cursor: pointer !important;
 }

 .answers{
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   margin-left: -75px;
  line-height: 30px;
 }

 .ikigaiTextFieldBlock{
   padding: 0px !important;
   /* border: 2px solid black;    */
   margin-left: -75px;
 }

 .answersIkigiDetails{
   font-family: 'Montserrat';
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   margin-left: -75px;
   line-height: 30px;
 }

 .ikigaiQuesimg {
  margin-left: 11px;
  margin-right: 10px;
  margin-top: 7px;
}