.myfeedback-widget--engager.is-ready {
  bottom: 30px;
  -webkit-transition: bottom 1s ease-in-out;
  transition: bottom 1s ease-in-out;
}

.myfeedback-widget--engager {
  position: fixed;
  bottom: -200px;
  z-index: 1299;
  -webkit-transition: bottom .2s ease-in-out;
  transition: bottom .2s ease-in-out;
}

.myfeedback-widget--left {
  left: 30px;
}

.myfeedback-widget {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  color: #272727;
}

.myfeedback-widget__caller {
  display: block;
  background: #fff;
  color: #272727;
  border-radius: 0.5rem;
  padding: 7px 15px;
  position: relative;
  -webkit-box-shadow: 0 8px 10px rgb(0 0 0 / 15%), 0 2px 0 #e4e4e4;
  box-shadow: 0 8px 10px rgb(0 0 0 / 15%), 0 2px 0 #e4e4e4;
  border: 1px solid #e9ecef;
  font-weight: 700;
  font-size: 12px;
  cursor: pointer;
}

.myfeedback-widget__caller:hover {
  box-shadow: 0 8px 10px rgb(0 0 0 / 18%), 0 2px 0 #e4e4e4c7;
}


.myfeedback-widget--left .myfeedback-widget__popup {
  left: 0;
}

.myfeedback-widget__popup {
  -webkit-transition: all .4s;
  transition: all .4s;
}

.myfeedback-widget__popup {
  width: 300px;
  background: #fff;
  position: absolute;
  padding: 0 10px;
  bottom: 40px;
  text-align: center;
  border-radius: 0.5rem;
  -webkit-box-shadow: 0 10px 30px rgb(0 0 0 / 15%);
  box-shadow: 0 10px 30px rgb(0 0 0 / 15%);
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: opacity .2s ease-in-out, bottom .1s ease-in-out, -webkit-transform .2s ease-in-out;
  transition: opacity .2s ease-in-out, bottom .1s ease-in-out, -webkit-transform .2s ease-in-out;
  transition: opacity .2s ease-in-out, transform .2s ease-in-out, bottom .1s ease-in-out;
  transition: opacity .2s ease-in-out, transform .2s ease-in-out, bottom .1s ease-in-out, -webkit-transform .2s ease-in-out;
}

.pointer-icon {
  position: absolute;
  bottom: -14px;
  left: 30px;
  color: #fff;
}

.questionCancelText {
  padding: 10px;
  color: #5c5959;
  border-radius: 5px;
  cursor: pointer;
  font-size: 12px;
  margin-right: 20px;
}

.questionSaveText {
  padding: 10px !important;
  color: white !important;
  background: #f56102 !important;
  border-radius: 5px !important;
  cursor: pointer !important;
  font-size: 12px !important;
  line-height: 1 !important;
}

.myfeedback-widget__popup__wrapper {
  padding: 20px;
}

.MuiButton-root.Mui-disabled {
  opacity: 0.7 !important;
  cursor: not-allowed;
}

.myfeedback-widget__popup {
  width: 400px;
  height: 500px;
}

@media (max-width: 767px) {
  .myfeedback-widget__popup {
    width: 80vw;
    height: 500px;
  }
}