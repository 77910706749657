.dashboard-dropdown.dropDownField {
  width: 120px;
}

.dashboard-dropdown.dropDownField.quarter {
  width: auto;
  margin-left: 20px;
  min-width: 100px;
}

.dashboard-dropdown.dropDownField .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
  background: #F9F9F9 !important;
}

.dash-tab {
  color: #3E4259;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  border-bottom: 10px solid #B9B9B9;
  line-height: 30px;
  cursor: pointer;
  height: 45px;
}

.dash-tab.active {
  color: #E96D17;
  border-bottom: 10px solid #E96D17;
  cursor: default;
}

.MuiBreadcrumbs-li a {
  cursor: pointer;
}

.icon-inside {
  font-size: 14px !important;
  color: #fff !important;
  padding: 3px;
  border-radius: 20px;
  font-weight: 600;
}

.icon-inside.red {
  background: #E93017;
}

.icon-inside.green {
  background: #37B256;
}

.MuiFormControlLabel-labelPlacementEnd {
  margin-right: 30px !important;
}

.MuiFormControlLabel-labelPlacementEnd .MuiFormControlLabel-label {
  color: #373737;
  font-weight: 600;
  font-size: 13px;
  margin-left: 3px;
}

.myTeamBox {
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.15);
  margin-bottom: 50px;
}

.extend-date-container .react-datepicker-wrapper {
  margin-right: 20px;
  width: 300px !important;
  margin-top: -5px;
}

.extend-date-container .react-datepicker__input-container {
  background: #fff !important;
}

.extend-date-container .react-datepicker__input-container input {
  font-size: 13px !important;
}

.date-label-custom.other {
  position: relative !important;
  padding: 25px 0 0px 1px !important;
  font-size: 13px !important;
  text-align: left !important;
}

.extend-date-container .MuiRadio-root.MuiRadio-colorPrimary.Mui-checked {
  color: #14009b !important;
}

.extend-date-modal .react-datepicker-popper {
  transform: translate3d(250px, 90px, 0px) !important;
}

.myTeamBox .tableBoxOther table th button.MuiButton-root.MuiButton-containedPrimary {
  background: none;
  color: #14009b;
  font-weight: 600;
}

.view-goal-modal .MuiDialog-paper {
  width: 1000px !important;
  max-width: 1000px !important;
  padding-top: 0 !important;
}

.view-goal-modal h2 {
  display: none;
}

.stats-data {
  color: #373737;
  font-weight: 600;
  font-size: 13px;
  margin-right: 20px;
}

.view-review-modal .MuiDialog-paper {
  width: 500px !important;
  max-width: 1000px !important;
  padding-top: 0 !important;
}

#dashboard-header {
  position: fixed !important;
}

@media screen and (max-width:900px) {
  .dash-tab {
    font-size: 15px;
  }

  #dashboardTabs {
    width: 170px !important;
    display: flex;
  }

  #dashboard-header {
    position: static !important;
  }
}

@media screen and (max-width:450px) {
  .dash-tab {
    font-size: 12px;
    border-bottom: none;
    text-align: left;
    margin-left: 10px;
  }

  #dashboardTabs {
    display: flex;
    flex-direction: column;
    height: 20px;
  }

  .dash-tab.active {
    border-bottom: none;
  }
}

@media screen and (max-width:799px) {
  #dashboardTabs {
    margin: auto;
    margin-bottom: 30px;
  }
}

@media screen and (max-width: 989px) {
  .userlist-header {
    width: 90% !important;
    margin: auto;
  }

  .checkbox-status {
    justify-content: space-around;
  }
}

@media screen and (max-width:990px) {
  .extend-date-modal .react-datepicker-popper {
    position: absolute !important;
    inset: 0px auto auto -250px !important;
  }

  .bottom-buttons {
    margin-top: 100px !important;
  }
}

@media screen and (max-width:500px) {
  .mobile-flex {
    flex-direction: column;
  }

}