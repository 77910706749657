.authPage {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  display: flex;
}

.authLeftSec {
  width: 40%;
  margin: 29px 34px;
  background-color: white !important;
  background-image: url("../../../assets/bgCircleLt.svg");
  background-repeat: no-repeat;
  border-radius: 8px;
  position: relative;
}

.authRightSec {
  width: 60%;
  text-align: center !important;
}

.loginImageDiv {
  padding-top: 50px;
  /* position: absolute;
  bottom: -4px; */
  height: auto;
  width: 100%;
}

.loginImage {
  width: 100%;
  background-color: white;
  border-radius: 8px;
}

.mainLogo {
  margin-top: 75px;
  width: 20%;
}

.mainsideLogo {
  margin-top: 50px;
  margin-right: 20px;
}

.authHead {
  margin: 85px 0 0 75px;
  font-size: 34px;
  font-weight: 600;
  color: #1f384c;
  line-height: 55px;
}

.authPara {
  margin: 10px 0 0 75px;
  font-size: 20px;
  font-weight: 500;
  color: #1f384c;
  width: 80%;
  line-height: 32px;
}

.loginHeading {
  font-weight: 600;
  font-size: 28px;
  color: #121625;
  margin-top: 35px;
}

.formField {
  width: 60%;
  margin: auto;
}

.formLable {
  margin-top: 30px;
  text-align: left;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 14px;
  color: #3a3c40;
}

.loginButton {
  padding-top: 40px;
  position: relative;
}

.textForm {
  width: 100%;
  margin-top: 25px;
  display: flex;
}

.leftText {
  width: 50%;
  text-align: initial;
  color: #121625;
  font-weight: 600;
  font-size: 14px;
}

.rightText {
  width: 50%;
  text-align: end;
  color: #14009b;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
}

.isMobile {
  display: none;
}

.login_error_msg {
  font-size: 13px;
  color: #d32f2f;
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  top: 10px;
}

.forgotPassBox {
  background: #f5f5f7;
  border: 1px solid #babfc5;
  border-radius: 8px;
  padding: 24px;
  width: 80%;
  margin: 50px auto 20px;
}

.forgotPassText {
  font-weight: 500;
  margin: auto;
  font-size: 14px;
  color: #82868c;
  margin-top: 20px;
}

.temporaryButton {
  color: white !important;
  background-color: #f56102 !important;
  border-color: #f56102 !important;
  width: 100%;
}

.goBack {
  margin-top: 10px;
  font-size: 12px;
}

.goBack a {
  color: #14009b;
  font-weight: 500;
  cursor: pointer;
}

.ssoBtn {
  color: #f56102 !important;
  font-size: 14px !important;
  border-radius: 5px;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #f56102 !important;
  padding: 8px 10px !important;
  border-radius: 6px !important;
  width: 60%;
  margin-top: 30px;

}

.ssoBtn:hover {
  font-weight: 500;
}

.sso-btn:hover {
  font-weight: 500;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
}

.outlookText {
  margin-top: 3px;
  display: block;
  font-size: 13px;
}

.outlookImage {
  background-image: url('../../../assets/outlook.png');
  height: 22px;
  width: 24px;
  background-size: contain;
  background-repeat: no-repeat;
  float: left;
}

@media screen and (max-width: 1300px) {
  .authHead {
    font-size: 27px;
    margin: 100px 30px 0 30px;
  }

  .authPara {
    font-size: 15px;
    margin: 12px 30px 0 30px;
  }
}

@media screen and (max-width: 1000px) {
  .authHead {
    font-size: 24px;
    line-height: 45px;
  }

  .loginImageDiv {
    padding: 0;
  }

  .authPara {
    font-size: 15px;
  }

  .authLeftSec {
    width: 50%;
  }

  .authRightSec {
    width: 50%;
  }

  .loginImage {
    margin-top: 4em;
    width: 100%;
    background-color: white;
  }

  .mainLogo {
    width: 33%;
  }

  .mainsideLogo {
    width: 33%;
  }

  .loginHeading {
    font-size: 20px;
    margin-top: 15px;
  }

  .formField {
    width: 85%;
  }

  .forgotPassBox {
    background: #f5f5f7;
    border: 1px solid #babfc5;
    border-radius: 8px;
    margin-top: 40px;
    padding: 15px;
    margin-bottom: 20px;
  }

  .forgotPassText {
    font-weight: 500;
    margin: auto;
    font-size: 11px;
    color: #82868c;
    margin-top: 12px;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .authPage {
    display: block;
  }

  .authRightSec {
    width: 100%;
  }

  .authLeftSec {
    width: 100%;
  }

  .authLeftSec {
    display: none;
  }

  .loginImage {
    margin-top: 2em;
    background-color: transparent;
  }

  .mainLogo {
    width: 27%;
    margin-top: 30px;
  }

  .mainsideLogo {
    width: 27%;
    margin-top: 30px;
  }

  .leftText {
    font-size: 12px;
  }

  .rightText {
    font-size: 12px;
    position: relative;
    top: 4px;
    cursor: pointer;
  }

  .isMobile {
    display: block;
    width: 100%;
    margin-top: 2em;
    background-color: transparent;
    border-radius: 8px;
  }

  .loginButton {
    margin-bottom: 30px;
  }
}