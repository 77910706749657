.dropDownField {
  margin-bottom: 15px;
  width: 100%;
}

.dropDownField .MuiSelect-select {
  padding: 7px 35px 7px 15px !important;
  font-size: 13px !important;
  font-weight: 600 !important;
  white-space: break-spaces !important;
  color: #14009b;
}

.MuiMenu-list {
  background: url("../../assets/bgBlueSmallRt.svg") right bottom no-repeat !important;
  padding: 0 !important;
  width: inherit !important;
}

ul .Mui-selected {
  background: rgba(20, 0, 155, 0.1) !important;
}

.dropDownButton {
  width: 100%;
  font-weight: 600 !important;
  font-size: 12px !important;
  color: #14009b !important;
  background: #ffffff !important;
  border: 0.5px solid #14009b !important;
  box-sizing: border-box !important;
  border-radius: 3px !important;
  cursor: pointer !important;
}

ul .MuiMenuItem-root:hover {
  background-color: transparent !important;
}

.MuiMenuItem-root .fieldInput {
  width: 95%;
}

.dropDownField .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-outlined {
  color: #333333;
  font-weight: 400;
  font-size: 13px;
  top: -5px;
}

.dropDownField .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink {
  color: #333333;
  font-weight: 500;
  font-size: 14px;
  top: 1px;
  background: #fff;
  padding: 0px 5px;
  left: -3px;
}

.dropDownField .MuiInputLabel-root.MuiInputLabel-formControl.MuiInputLabel-animated.MuiInputLabel-shrink.Mui-focused {
  color: #1976d2;
}

.dropDownField.surveyList-report {
  margin-bottom: 0;
}

.dropDownField.surveyList-report fieldset {
  border: 0;
}

.dropDownField.surveyList-report .MuiSelect-select {
  padding: 0 32px 0 0 !important;
  line-height: 1;
  color: inherit;
}

.dropDownField.surveyList-report .MuiSvgIcon-root {
  top: -5px;
}

.MuiSelect-select.MuiSelect-outlined.MuiOutlinedInput-input.MuiInputBase-input .actionBtn {
  display: none;
}

@media screen and (max-width: 1000px) {
  .dropDownField .MuiSelect-select {
    font-size: 10px !important;
  }
}